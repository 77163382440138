@import './blog';

body.chakra-ui-dark {
  background-color: #1A202C;
}

.wobble-hor-bottom {
  -webkit-animation: wobble-hor-bottom 15s infinite both;
  animation: wobble-hor-bottom 15s infinite both;
}

@-webkit-keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 100% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-1.5deg);
    transform: translateX(-30px) rotate(-1.5deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(1.5deg);
    transform: translateX(15px) rotate(1.5deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-1.6deg);
    transform: translateX(-15px) rotate(-1.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(0.8deg);
    transform: translateX(9px) rotate(0.8deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-0.2deg);
    transform: translateX(-6px) rotate(-0.2deg);
  }
}

@keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 100% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-1.5deg);
    transform: translateX(-30px) rotate(-1.5deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(1.5deg);
    transform: translateX(15px) rotate(1.5deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-1.6deg);
    transform: translateX(-15px) rotate(-1.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(0.8deg);
    transform: translateX(9px) rotate(0.8deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-0.2deg);
    transform: translateX(-6px) rotate(-0.2deg);
  }
}

.slide-in-bottom-2 {
  -webkit-animation: slide-in-bottom 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-bottom-1 {
  -webkit-animation: slide-in-bottom 1.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 1.1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

// Mapping editor
.ͼ1.cm-focused {
  outline: none;
}

.cm-key {
  color: #6BAA9F;
}

.cm-paren,
.cm-paren .cm-string {
  color: #CBD5E0;
}

.cm-utilityFuncParams .cm-numberParam > .cm-string,
.cm-numberParam > .cm-string,
.cm-numberParam,
.cm-number {
  color: #ffd866;
}

.cm-utilityFuncParams .ͼ1c,
.cm-string {
  color: #FF8170;
}

.cm-templateString,
.cm-templateString > *,
.cm-templateString > .cm-string,
.cm-utilityFunc {
  color: #6BDFFF;
}

.cm-utilityFunc [class^="ͼ"],
.cm-utilityFunc .cm-string,
.cm-utilityFunc {
  color: #DABAFF;
}

.cm-utilityFuncParams .cm-string,
.cm-utilityFuncParams {
  color: #FF7AB2;
}

.cm-utilityFuncParams [class^="ͼ"],
.cm-quotedParamString [class^="ͼ"],
.cm-quotedParamString .cm-utilityFuncParams,
.cm-quotedParamString .cm-utilityFuncParams [class^="ͼ"],
.cm-quotedParamString {
  color: #FF8170 !important;
}

.cm-comment .cm-utilityFunc,
.cm-comment {
  color: #7F8C98;
}

#body-map-editor .cm-editor {
  min-height: 150px;
  padding: 0.5rem;
  overflow: hidden;
  resize: vertical;
}
